<script>
import { layoutMethods } from "@/state/helpers";
/**
 * Right-sidebar
 */
export default {
  data() {
    return {
      config: {
        handler: this.handleRightBarClick,
        middleware: this.middleware,
        events: ["click"],
      },
      layout: this.$store ? this.$store.state.layout.layoutType : {} || {},
      width: this.$store ? this.$store.state.layout.layoutWidth : {} || {},
      topbar: this.$store ? this.$store.state.layout.topbar : {} || {},
      sidebarType: this.$store
        ? this.$store.state.layout.leftSidebarType
        : {} || {},
      loader: this.$store ? this.$store.state.layout.loader : {} || {},
    };
  },
  methods: {
    ...layoutMethods,
    hide() {
      this.$parent.toggleRightSidebar();
    },
    // eslint-disable-next-line no-unused-vars
    handleRightBarClick(e, el) {
      this.$parent.hideRightSidebar();
    },
    // eslint-disable-next-line no-unused-vars
    middleware(event, el) {
      if (event.target.classList)
        return !event.target.classList.contains("toggle-right");
    },
    changeLayout(layout) {
      this.changeLayoutType({
        layoutType: layout,
      });
    },
    changeType(type) {
      return this.changeLeftSidebarType({
        leftSidebarType: type,
      });
    },
    changeWidth(width) {
      return this.changeLayoutWidth({
        layoutWidth: width,
      });
    },
    changeTopbartype(value) {
      return this.changeTopbar({
        topbar: value,
      });
    },
    changeloader() {
      return this.changeLoaderValue({
        loader: this.loader,
      });
    },
  },
};
</script>

<template>
  <div>
    <!-- Right Sidebar -->
    <div v-click-outside="config" class="right-bar">
      <div data-simplebar class="h-100">
        <div class="rightbar-title px-3 py-4">
          <a
            href="javascript:void(0);"
            class="right-bar-toggle float-right"
            @click="hide"
          >
            <i class="mdi mdi-close noti-icon"></i>
          </a>
          <h5 class="m-0">More Filters</h5>
        </div>

        <div class="p-3">
          <h6 class="mb-0">Status</h6>
          <hr class="mt-1" />
          <b-form-radio-group v-model="layout" stacked>
            <b-form-radio value="vertical" class="mb-1">Open</b-form-radio>
            <b-form-radio value="horizontal" class="mb-1"
              >Archived</b-form-radio
            >
            <b-form-radio value="horizontal" class="mb-1"
              >Canceled</b-form-radio
            >
          </b-form-radio-group>

          <h6 class="mt-3">Payment Status</h6>
          <hr class="mt-1" />
          <div class="form-check mb-3">
            <input
              class="form-check-input"
              type="checkbox"
              value
              id="defaultCheck1"
            />
            <label class="form-check-label" for="defaultCheck1">Paid</label>
          </div>
          <div class="form-check mb-3">
            <input
              class="form-check-input"
              type="checkbox"
              value
              id="defaultCheck1"
            />
            <label class="form-check-label" for="defaultCheck1">Unpaid</label>
          </div>
          <div class="form-check mb-3">
            <input
              class="form-check-input"
              type="checkbox"
              value
              id="defaultCheck1"
            />
            <label class="form-check-label" for="defaultCheck1">Refunded</label>
          </div>
          <div class="form-check mb-3">
            <input
              class="form-check-input"
              type="checkbox"
              value
              id="defaultCheck1"
            />
            <label class="form-check-label" for="defaultCheck1"
              >Partially refunded</label
            >
          </div>
          <div class="form-check mb-3">
            <input
              class="form-check-input"
              type="checkbox"
              value
              id="defaultCheck1"
            />
            <label class="form-check-label" for="defaultCheck1"
              >Partially paid</label
            >
          </div>
          <div class="form-check mb-3">
            <input
              class="form-check-input"
              type="checkbox"
              value
              id="defaultCheck1"
            />
            <label class="form-check-label" for="defaultCheck1"
              >Authorized</label
            >
          </div>
          <div class="form-check mb-3">
            <input
              class="form-check-input"
              type="checkbox"
              value
              id="defaultCheck1"
            />
            <label class="form-check-label" for="defaultCheck1">Void</label>
          </div>

          <div v-if="layout === 'vertical'">
            <h6 class="mt-3">Delivery Method</h6>
            <hr class="mt-1" />
            <b-form-radio-group v-model="sidebarType" stacked>
              <b-form-radio value="dark" class="mb-1"
                >Local Delivery</b-form-radio
              >
              <b-form-radio value="light" class="mb-1"
                >Local Pickup</b-form-radio
              >
              <b-form-radio value="compact" class="mb-1"
                >Ship to customer</b-form-radio
              >
            </b-form-radio-group>
          </div>

          <div v-if="layout === 'horizontal'">
            <h6 class="mt-3">Fulfillment Status</h6>
            <hr class="mt-1" />
            <div class="form-check mb-3">
              <input
                class="form-check-input"
                type="checkbox"
                value
                id="defaultCheck1"
              />
              <label class="form-check-label" for="defaultCheck1"
                >Fulfilled</label
              >
            </div>
            <div class="form-check mb-3">
              <input
                class="form-check-input"
                type="checkbox"
                value
                id="defaultCheck1"
              />
              <label class="form-check-label" for="defaultCheck1"
                >Unfulfilled</label
              >
            </div>
            <div class="form-check mb-3">
              <input
                class="form-check-input"
                type="checkbox"
                value
                id="defaultCheck1"
              />
              <label class="form-check-label" for="defaultCheck1"
                >Partially Fulfilled</label
              >
            </div>
            <div class="form-check mb-3">
              <input
                class="form-check-input"
                type="checkbox"
                value
                id="defaultCheck1"
              />
              <label class="form-check-label" for="defaultCheck1"
                >Scheduled</label
              >
            </div>
            <div class="form-check mb-3">
              <input
                class="form-check-input"
                type="checkbox"
                value
                id="defaultCheck1"
              />
              <label class="form-check-label" for="defaultCheck1"
                >Partially paid</label
              >
            </div>
            <div class="form-check mb-3">
              <input
                class="form-check-input"
                type="checkbox"
                value
                id="defaultCheck1"
              />
              <label class="form-check-label" for="defaultCheck1"
                >On hold</label
              >
            </div>

            <!-- Preloader -->
            <h6 class="mt-3">Preloader</h6>
            <hr class="mt-1" />

            <b-form-checkbox
              v-model="loader"
              name="check-button"
              switch
              @input="changeloader"
              >Preloader</b-form-checkbox
            >
          </div>
          <!-- Settings -->

          <div class="p-4"></div>
        </div>
      </div>
      <!-- end slimscroll-menu-->
    </div>
    <!-- /Right-bar -->

    <!-- Right bar overlay-->
    <div class="rightbar-overlay"></div>
  </div>
</template>
