export const menuItems = [
  {
    id: 1,
    label: "menuitems.menu.text",
    isTitle: true,
  },
  {
    id: 2,
    label: "Dashboard",
    icon: "fas fa-home",
    link: "/dashboard",
  },
  {
    id: 26,
    icon: "fas fa-tshirt",
    label: "Order Management",
    link: "/orders",
  },
  {
    id: 25,
    icon: "ri-service-fill",
    label: "Profile Management",
    link: "/profile-management",
  },
  
];
